<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader :isShow="isShow" :btn="btn" :strForm="clauForm" @openEdit="openEdit" @closeEdit="closeEdit" @submit="submit('clauForm')" />
      <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
      <div class="vd_export2 vg_mr_8">
        <span style="margin-right: 2px">文本信息流水号:</span>
        <el-input size="mini" style="width: 150px" disabled v-model="clauForm.clau_no" show-word-limit> </el-input>
      </div>
    </div>
    <el-form ref="clauForm" :model="clauForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="文本部门" prop="clau_dept_id">
            <!--            <el-input maxlength="10" v-model="clauForm.clau_label" placeholder="请填写条款标签" show-word-limit @input="formatInput" @blur="applyUpperCase">-->
            <!--            </el-input>-->
            <el-select v-model="clauForm.clau_dept_id" placeholder="请选择文本部门" size="small" style="width: 200px" clearable filterable>
              <el-option v-for="item in deptList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-form-item label="文本名称" prop="clau_name">
            <el-input maxlength="30" v-model="clauForm.clau_name" placeholder="请填写条款名称" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-form-item label="文本内容" prop="clau_content">
            <el-input v-model="clauForm.clau_content" type="textarea" :rows="4" show-word-limit placeholder="请填写条款内容"> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="clauForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { clauAPI } from '@api/modules/clau';
import { imgeAPI } from '@api/modules/imge';
import { suppAPI } from '@api/modules/supp';
import { optnAPI } from '@api/modules/optn';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import helper from '@assets/js/helper';
import UrlEncode from '@assets/js/UrlEncode';
import { getDept } from '@api/public';

export default {
  name: 'clauEditMain',
  components: {
    editHeader,
    inputUser
  },
  data() {
    return {
      rules: {
        clau_label: [{ required: true, trigger: 'blur', message: ' ' }],
        clau_name: [{ required: true, trigger: 'blur', message: ' ' }],
        clau_content: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      clauForm: {
        clau_label: null,
        clau_name: null,
        clau_content: null,
        clau_dept_id: null
      },
      isShow: true,
      btn: {},
      stffForm: {},
      loadingFlag: true,
      inputValue: '',
      deptList: []
    };
  },
  created() {
    this.initData();
  },
  watch: {
    // 如果formattedValue发生变化，同步到inputValue
    'clauForm.clau_label'(newValue) {
      this.inputValue = newValue;
    }
  },
  methods: {
    async initData() {
      this.loadingFlag = true;
      this.getclauInfo();
      this.deptList = await getDept();
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      let clauForm = JSON.parse(JSON.stringify(this.clauForm));
      clauForm.clau_id = props.form_id;
      clauForm.personal = this.$cookies.get('userInfo').personal;
      clauForm.clau_dept_name = this.deptList.find(x => x.value === clauForm.clau_dept_id).label;
      post(clauAPI.editClau, clauForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    formatInput(val) {
      // 处理数字间空格，这里简化处理逻辑，确保在数字后直接跟空格时不允许
      const cleanedInput = val.replace(/(\d)\s+(\d)/g, '$1$2');
      // 更新到formattedValue触发双向绑定更新视图，同时准备下一步大写转换
      this.clauForm.clau_label = cleanedInput.toUpperCase();
    },
    applyUpperCase() {
      // 同步到原始inputValue，根据业务需求决定是否需要这一步
      this.inputValue = this.clauForm.clau_label;
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('clauForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取表单信息
    getclauInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      this.clauForm.clau_id = props.form_id;
      get(clauAPI.getClauById, { clau_id: this.clauForm.clau_id })
        .then(res => {
          if (res.data.code === 0) {
            this.clauForm = res.data.data.form;
            this.btn = res.data.data.btn;
            this.stffForm.stff_name = this.clauForm.stff_name;
            this.stffForm.dept_name = this.clauForm.dept_name;
            this.stffForm.dept_team_name = this.clauForm.dept_team_name;
            this.stffForm.user_id = this.clauForm.user_id;
            this.stffForm.dept_id = this.clauForm.dept_id;
            this.stffForm.stff_id = this.clauForm.stff_id;
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}

.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}

.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_mrt1 {
  margin-top: 1px;
}

::v-deep .el-upload--text {
  height: 300px;
  width: 300px;
}

::v-deep .el-upload-dragger {
  height: 300px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vd_dis {
  display: flex;
  align-items: center;
}
.vd_flex_right {
  cursor: pointer;
  box-sizing: border-box;
  padding-left: 10px;
}
.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 120px;
}
.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
</style>
